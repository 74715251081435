import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { CircularProgress, Grid } from '@mui/material';

import ActivityAtEdu from 'components/userProfileInfo/activityAtEDU/ActivityAtEdu';
import ActivityByCareer from 'components/userProfileInfo/activityByCareer/ActivityByCareer';
import ActivityMoodle from 'components/userProfileInfo/activityMoodle/ActivityMoodle';
import HeaderProfile from 'components/userProfileInfo/headerProfile/HeaderProfile';
import StudentInfo from 'components/userProfileInfo/studentInfo/StudentInfo';
import UserProfileService from 'services/userProfile/userProfile.service';
import { IUserProfile } from '../UserProfile.interface';
import useStyles from '../UserProfile.styles';
import { IHeaderProfileData } from 'components/userProfileInfo';
import UserService from 'services/user/user.service';

/**
 * Student Profile displays the student's data shows different components such as [[ActivityAtEdu]],
 * [[ActivityByCareer]], [[ActivityMoodle]], [[HeaderProfile]] and [[StudentInfo]] and use [[studentProfileService]]
 *
 * @component PerfilEstudiante
 */
const PerfilEstudiante = (props: any) => {
  const style = useStyles();
  const search = props.location.search;
  const id = new URLSearchParams(search).get('id') || '0';
  const initialPlan = new URLSearchParams(search).get('plan') || '0';
  const [plans, setPlans] = useState<string[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<string>(initialPlan);
  const [selectedId, setSelectedId] = useState<string>(id);
  const loggedUser = UserService.getSessionUser();

  useEffect(() => {
    async function fetchPlans() {
      const result: string[] = await UserProfileService.getPossiblePlans(
        id,
        initialPlan,
      );
      setPlans(result);
    }

    fetchPlans();
  }, [id]);

  const empyInfo: any = [];
  const [studentData, setStudentData] = useState(empyInfo);
  const fetchStudentsProfile = async (
    selectedId: string,
    selectedPlan: string,
  ) => {
    try {
      const result: any = await UserProfileService.getStudentData(
        selectedId,
        selectedPlan,
        props.filters,
      );
      setStudentData(result);
    } catch (error) {
      console.error('Error fetching student data:', error);
    }
  };

  useEffect(() => {
    fetchStudentsProfile(selectedId, selectedPlan);
  }, [selectedId, selectedPlan]);

  if (studentData.length === 0) {
    return (
      <Grid item xs={12} className={style.loading}>
        <CircularProgress />
      </Grid>
    );
  } else {
    return (
      <Grid container>
        <Grid item xs={12}>
          <HeaderProfile
            data={studentData.headerProfile}
            planes={plans}
            selectedId={selectedId}
            selectedPlan={selectedPlan}
            fetchStudentsProfile={fetchStudentsProfile}
          />
        </Grid>
        {loggedUser?.rol?.toLowerCase() !== 'uruguay global' && (
          <Grid item xs={12}>
            <ActivityMoodle data={studentData.activityMoodle} />
          </Grid>
        )}
        <Grid item xs={12}>
          <ActivityByCareer data={studentData.activityByCareer} />
        </Grid>
        {loggedUser?.rol?.toLowerCase() !== 'uruguay global' && (
          <Grid item xs={12}>
            <ActivityAtEdu data={studentData.activityByEdu} />
          </Grid>
        )}
        <Grid item xs={12}>
          <StudentInfo data={studentData.studentProfile} />
        </Grid>
      </Grid>
    );
  }
};

const mapStateToProps = (state: any) => {
  return { filters: state.filters };
};

export default connect(mapStateToProps)(PerfilEstudiante);
