import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Button } from '@material-ui/core';
import useLists from 'utils/customHooks/useLists';
import { makeStyles } from '@material-ui/core/styles';

interface EncuestasCListadoProps {
  filters: any;
}

interface IListResponse {
  [key: string]: any;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  rowEven: {
    backgroundColor: '#f5f5f5',
  },
  rowOdd: {
    backgroundColor: '#ffffff',
  },
  tableCell: {
    whiteSpace: 'pre-line',
    fontWeight: 'normal',  
    fontSize: '14px', // Cambia el tamaño de la fuente aquí
    '& td': {              
      fontWeight: 'normal',
    }
  },
  commentCell: {
    whiteSpace: 'pre-line',
    lineHeight: 1.8,
    fontWeight: 'normal', 
    fontSize: '14px', // Aplica el mismo tamaño aquí si es necesario
    '& td': {
      fontWeight: 'normal', 
    }
  },
  nameCell: {
    fontSize: '14px', // También puedes definir un tamaño específico para las celdas de nombre
  },
  hiddenHeader: {
    display: 'none',
  },
  notes: {
    marginTop: '10px',
    fontStyle: 'italic',
    backgroundColor: 'transparent',
    fontSize: '12px', // Ajusta el tamaño de las notas si es necesario
  },
});



const EncuestasCListado: React.FC<EncuestasCListadoProps> = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  // Fetch data for UC
  const { users: answersResults1S } = useLists('/v1.1/encuestascoordinador/listado1S', props.filters) as { users: IListResponse[] };
  const { users: answersResults2S } = useLists('/v1.1/encuestascoordinador/listado2S', props.filters) as { users: IListResponse[] };
  const { users: comentariosDocentes1S } = useLists('/v1.1/encuestascoordinador/comentariosDocentes1S', props.filters) as { users: IListResponse[] };
  const { users: comentariosDocentes2S } = useLists('/v1.1/encuestascoordinador/comentariosDocentes2S', props.filters) as { users: IListResponse[] };

  useEffect(() => {
    setLoading(true); 
  }, [props.filters]);


  useEffect(() => {
    if (
      answersResults1S.length > 0 &&
      answersResults2S.length > 0 &&
      comentariosDocentes1S.length > 0 &&
      comentariosDocentes2S.length > 0
    ) {
      setLoading(false); 
    } else {
      setLoading(false); 
    }
  }, [answersResults1S, answersResults2S, comentariosDocentes1S, comentariosDocentes2S]);

  const headers = [
    'ID',
    'Nombre Docente',
    'Curso',
    'Cumple adecuadamente con la planificación semanal del curso (horario, asistencia).',
    'Desarrolla los temas de forma clara y entendible.',
    'En términos generales, ¿Cuán satisfecho estás con la actuación del/la docente?',
    'Es accesible y está dispuesto a ayudarme cuando lo solicito.',
    'Las instancias de intercambio (clases) están bien preparadas y organizadas.',
    'Promueve la participación y reflexión de los estudiantes.',
    'Realiza instancias de corrección para evacuar dudas y que el estudiante vea claramente donde debe mejorar.',
    'Tiene un trato respetuoso y cordial.',
    'Usa ejemplos y casos relacionados con la vida real.',
    'Utiliza recursos variados como apoyo didáctico (por ej. presentaciones, herramientas TIC, plataforma, salidas didácticas).',
    'Total de estudiantes que respondieron.',
    'Total de estudiantes inscriptos a la UC.'
  ];

  const commentHeaders = ['Nombre Docente', 'Comentario'];

  const transformData = (data: IListResponse[], headers: string[], includeHeaders: boolean = true): any[][] => {
    if (data.length === 0) return [];

    const rows = data.map(item => {
      const nombreDocente = item[0] || '';
      const curso = item[1] || '';

      const promedios = item.slice(2);

      const paddedPromedios = promedios.length < headers.length - 2 
        ? promedios.concat(Array(headers.length - 2 - promedios.length).fill('')) 
        : promedios.slice(0, headers.length - 2);

      return [
        nombreDocente,
        curso,
        ...paddedPromedios
      ];
    });

    return includeHeaders ? [headers, ...rows] : rows;
  };

  const cleanDuplicateNames = (name: string): string => {
    const nameLength = name.length;
    
    for (let i = 1; i <= nameLength / 2; i++) {
      const firstPart = name.slice(0, i);
      const secondPart = name.slice(i, i + firstPart.length);
      
      if (firstPart === secondPart) {
        return firstPart;
      }
    }
    
    return name;
  };

  const transformComments = (data: IListResponse[]): any[][] => {
    if (data.length === 0) return [];
  
    const groupedComments: { [key: string]: string[] } = {};
  
    data.forEach(item => {
      const nombreDocente = item[0] || '';
      const comentario = item[1] || '';
      const cleanedNombreDocente = cleanDuplicateNames(nombreDocente);
  
      if (!groupedComments[cleanedNombreDocente]) {
        groupedComments[cleanedNombreDocente] = [];
      }
      groupedComments[cleanedNombreDocente].push(`• ${comentario}`);
    });
  
    const rows = Object.keys(groupedComments).map(nombreDocente => {

      const formattedComments = groupedComments[nombreDocente]
        .map(comment => comment.replace(/\n/g, '\n• ')) 
        .join('\n'); 
  
      return [nombreDocente, formattedComments];
    });
  
    return [commentHeaders, ...rows];
  };

  const transformedAnswersResults1S = transformData(answersResults1S, headers);
  const transformedAnswersResults2S = transformData(answersResults2S, headers);
  const transformedComentariosDocentes1S = transformComments(comentariosDocentes1S);
  const transformedComentariosDocentes2S = transformComments(comentariosDocentes2S);

  const convertToCSV = (data: any[][]): string => {
    return data.map(row => row.join(',')).join('\n');
  };

  const downloadCSV = (data: any[][], filename: string) => {
    const csv = convertToCSV(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) { 
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleExportAnswers1S = () => {
    downloadCSV(transformedAnswersResults1S, 'encuestas_docentes_1S.csv');
  };

  const handleExportComments1S = () => {
    downloadCSV(transformedComentariosDocentes1S, 'comentarios_docentes_1S.csv');
  };

  const handleExportAnswers2S = () => {
    downloadCSV(transformedAnswersResults2S, 'encuestas_docentes_2S.csv');
  };

  const handleExportComments2S = () => {
    downloadCSV(transformedComentariosDocentes2S, 'comentarios_docentes_2S.csv');
  };

  const renderTable = (data: any[][], headers?: string[], hideFirstRows: number = 0, hideID: boolean = false) => {

    const filteredData = data.filter(row => {
      return !(row[0] === 'docente' && row[1] && row[1].includes('respuestas'));
    });

    return (
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          {headers && (
            <TableHead>
              <TableRow>
                {headers.map((header: string, index: number) => {
                  if (hideID && index === 0) return null;
                  return <TableCell key={index}>{header}</TableCell>;
                })}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {filteredData.slice(hideFirstRows).map((row: any[], rowIndex: number) => (
             <TableRow
             key={rowIndex}
             className={
               rowIndex % 2 === 0
                 ? classes.rowEven
                 : classes.rowOdd
             }
           >
             {row.map((cell: any, cellIndex: number) => {
               if (hideID && cellIndex === 0) return null;
               const cellStyle = headers === commentHeaders ? classes.commentCell : classes.tableCell;
               return <TableCell key={cellIndex} className={`${cellStyle} normalFont`}>{cell}</TableCell>; // Aplica normalFont aquí
             })}
           </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Grid container spacing={2} style={{ marginTop: '0px' }}>
      <Grid item xs={12}>
        {loading ? (
          <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <CircularProgress />
            <Typography variant="h6" style={{ marginTop: '10px' }}></Typography>
          </div>
        ) : (
          <>

            <Typography variant="h6">Reportes Coordinación Docentes</Typography>
            <Typography variant="h6">Primer Semestre</Typography>
            {transformedAnswersResults1S.length > 0 ? (
              <>
                <Typography variant="h6">Encuestas Docentes 1S:</Typography>
                {renderTable(transformedAnswersResults1S, headers, 2, true)}
                <Typography className={classes.notes}>
  * El promedio refiere a la frecuencia con que se desarrollan los items consultados, donde 1 es Nunca y 4 es Siempre.
</Typography>
<Typography className={classes.notes}>
  ** No se incluye en el promedio las respuestas "No tengo elementos para evaluarlo" y "No sabe no contesta".
</Typography>
<Typography className={classes.notes}>
  Las UCs con menos de cuatro respuestas no se evalúan para mantener el anonimato de los estudiantes.
</Typography>

                <Button variant="contained" color="primary" onClick={handleExportAnswers1S}>Exportar Encuestas Docentes 1S</Button>
              </>
            ) : (
              <Typography variant="body1">No hay datos para el periodo 1S.</Typography>
            )}

            {transformedComentariosDocentes1S.length > 0 ? (
              <>
                <Typography variant="h6" style={{ marginTop: '20px' }}>Comentarios Docentes 1S</Typography>
                {renderTable(transformedComentariosDocentes1S, commentHeaders, 1)}  
                <Button variant="contained" color="primary" onClick={handleExportComments1S} style={{ marginLeft: '10px' }}>Exportar Comentarios Docentes 1S</Button>
              </>
            ) : (
              <Typography variant="body1">No hay comentarios para los docentes en 1S.</Typography>
            )}

            <Typography variant="h6" style={{ marginTop: '20px' }}>Segundo Semestre</Typography>
            {transformedAnswersResults2S.length > 0 ? (
              <>
                <Typography variant="h6">Encuestas Docentes 2S:</Typography>
                {renderTable(transformedAnswersResults2S, headers, 2, true)}
                <Typography className={classes.notes}>
  * El promedio refiere a la frecuencia con que se desarrollan los items consultados, donde 1 es Nunca y 4 es Siempre.
</Typography>
<Typography className={classes.notes}>
  ** No se incluye en el promedio las respuestas "No tengo elementos para evaluarlo" y "No sabe no contesta".
</Typography>
<Typography className={classes.notes}>
  Las UCs con menos de cuatro respuestas no se evalúan para mantener el anonimato de los estudiantes.
</Typography>

                <Button variant="contained" color="primary" onClick={handleExportAnswers2S} style={{ marginTop: '10px' }}>Exportar Encuestas Docentes 2S</Button>
              </>
            ) : (
              <Typography variant="body1">No hay datos para el periodo 2S.</Typography>
            )}

            {transformedComentariosDocentes2S.length > 0 ? (
              <>
                <Typography variant="h6" style={{ marginTop: '20px' }}>Comentarios Docentes 2S</Typography>
                {renderTable(transformedComentariosDocentes2S, commentHeaders, 1)}  
                <Button variant="contained" color="primary" onClick={handleExportComments2S} style={{ marginLeft: '10px', marginTop: '10px' }}>Exportar Comentarios Docentes 2S</Button>
              </>
            ) : (
              <Typography variant="body1">No hay comentarios para los docentes en 2S.</Typography>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: any) => ({
  filters: state.filters,
});

export default connect(mapStateToProps)(EncuestasCListado);
